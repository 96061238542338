import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M0.394531 69.707C1.79427 67.6562 4.07292 63.9128 7.23047 58.4766C8.5651 56.1654 10.1602 53.3659 12.0156 50.0781C13.8711 46.7904 16.0358 42.9004 18.5098 38.4082C20.9837 33.916 23.7832 28.7728 26.9082 22.9785C30.0658 17.1842 33.5977 10.625 37.5039 3.30078L22.2207 2.91016C21.5697 3.00781 20.7233 3.2194 19.6816 3.54492C18.6725 3.87044 17.5658 4.42383 16.3613 5.20508C15.1569 5.98633 13.9036 7.02799 12.6016 8.33008C11.332 9.63216 10.1113 11.3249 8.93945 13.4082C7.76758 15.459 6.70964 17.9329 5.76562 20.8301C4.85417 23.7272 4.13802 27.1452 3.61719 31.084L1.81055 30.9863L2.68945 0.615234L60.0137 1.88477C58.5814 3.9681 56.2865 7.71159 53.1289 13.1152C51.7943 15.4264 50.1992 18.2259 48.3438 21.5137C46.4883 24.8014 44.3236 28.6751 41.8496 33.1348C39.4082 37.5944 36.625 42.7051 33.5 48.4668C30.4076 54.2285 26.9408 60.7389 23.0996 67.998L38.9199 68.0957C39.5059 68.0306 40.3034 67.8353 41.3125 67.5098C42.3542 67.1842 43.4772 66.6471 44.6816 65.8984C45.8861 65.1172 47.1393 64.0755 48.4414 62.7734C49.7435 61.4388 50.9805 59.7298 52.1523 57.6465C53.3242 55.5632 54.3659 53.0404 55.2773 50.0781C56.2214 47.0833 56.9212 43.5514 57.377 39.4824L59.1836 39.5801L58.793 70L0.394531 69.707Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
